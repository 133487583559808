import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row, Col } from "react-grid-system";
import { Table, Modal, Input, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import uuid from "react-uuid";
import moment from 'moment';
import api1 from "../..";
import {config,msalConfig,loginApiRequest, apiConfig} from "../../config";


 const VALUE_MAPPING = process.env.REACT_APP_API_VM;
 const SAVE_VALUE_MAPPING = process.env.REACT_APP_API_VM_SAVE;
 const DELETE_VALUE_MAPPING= process.env.REACT_APP_API_VM_DEL;
 const  SYNC_TRIGGER = process.env.REACT_APP_API_VM_SYNC;

function ValueMapping() {
  

  const [sourcesystem, setSourceSystem] = useState("");

  const [loading, setLoading] = useState(false);

  const [editvisible, setEditVisible] = useState(false);
  const [editingmapping, setEditingMapping] = useState(null);

  const [addvisible, setAddVisible] = useState(false);
  const [addmapping, setAddMapping] = useState(null);

  const [newfromtext, setNewFromText] = useState("");
  const [newtotext, setNewToText] = useState("");

  const [deletepopup, setDeletePopup] = useState(false);
  const [deletemapping, setDeleteMapping] = useState(null);

  const [dataSource, setDataSource] = useState([]);

  const [sourcesystemselected, setSourceSystemSelected] = useState(false);



  const columns = [
    {
      title: `Mapping From (${sourcesystem})`,
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Mapping To",
      dataIndex: "value",
      key: "value",
    },

    {
      title:"Created At",
      dataIndex: "createdTimeStamp",
      key:"createdTimeStamp",
      render: (record) => {
        return (
          <div> 
            <p>{moment(record).format("DD-MM-YYYY, h:mma")}</p>
          </div>
        );
      },
      

    },
    
    {
      title:"Last Modified",
      dataIndex: "lastModifiedTimeStamp",
      key:"lastModifiedTimeStamp",
      render: (record) => {
        return (
          <div> 
            <p>{moment(record).format("DD-MM-YYYY, h:mma")}</p>
          </div>
        );
      },
      

    },

    {
      title:"Status",
      dataIndex: "status",
      key:"status",
    },
    
    {
      title: "Actions",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                onEditMapping(record);
              }}
              style={{ marginLeft: 10 }}
            />
            <DeleteOutlined
              onClick={() => {setDeletePopup(true); setDeleteMapping({ ...record })}}
              style={{ color: "red", marginLeft: 20 }}
              disabled={true}
            />
          </>
        );
      },
    },
  ];

  

  function changeDropdownText(text) {
    setSourceSystem(text.trim());
  }

  const updateSourceSystem = async (source) => {
    console.log(source);

    // define body of request, i.e. selected source system
    const data = {
      sourceSystem: source.trim(),
    };

    var mappings;

    console.log('Data',data);

  



    try {
      // add api call
      setLoading(true);
      const response = await api1.post(
         VALUE_MAPPING,
        data,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
            "Content-Type": "application/json",
          },
         }
      );
      setLoading(false);

      mappings = response.data;

      console.log(response.data);
    } catch (error) {
      console.log(error);
    }

    // loop through mappings and apply ID's
    mappings.forEach(function (obj) {
      obj.id = uuid();
    });

    // remove mappings that are discarded
    mappings = mappings.filter(row => row.status !== "DISCARDED")

    console.log(mappings);

    // update source mappings list
    setDataSource(mappings);
  };

  const onAddMapping = () => {
    // add two text boxes and allow user to enter mappings
    // before running this function

    const removeExtraSpacefrom = newfromtext.trim().split(/ +/).join(" ");
    const removeExtraSpaceto = newtotext.trim().split(/ +/).join(" ");

    const addMapping = async () => {
      const data = {
        sourceSystem: sourcesystem,
        key: newfromtext,
        value: newtotext,
      };

      try {
        // add api call
        setLoading(true);
        const response = await api1.post(
          SAVE_VALUE_MAPPING,
          data,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
              "Content-Type": "application/json",
            },
          }
        );

        setLoading(false);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    addMapping();

    if (removeExtraSpacefrom !== "" && removeExtraSpaceto !== "") {
      const newMapping = {
        id: uuid(),
        key: newfromtext,
        value: newtotext,
      };

      setDataSource((pre) => {
        return [...pre, newMapping];
      });
    } else {
      alert("Enter valid mapping");
    }
  };

  const onDeleteMapping = (record) => {
    const deleteMapping = async (record) => {
      const data = {
        sourceSystem: sourcesystem,
        key: record.key,
        value: record.value
      };

      try {
        // add api call
        setLoading(true);
        const response = await api1.post(
         DELETE_VALUE_MAPPING,
          data,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
              "Content-Type": "application/json",
            },
          }
        );
        setLoading(false);
        setDataSource((pre) => {
          return pre.filter((mapping) => mapping.id !== record.id);
        });
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    deleteMapping(record);
    
  };

  // edit mapping
  const onEditMapping = (record) => {
    setEditVisible(true);
    setEditingMapping({ ...record });
  };

  const updateMapping = async (record) => {
    const data = {
      sourceSystem: sourcesystem,
      key: record.key,
      value: record.value,
    };

    try {
      // add api call
      setLoading(true);
      const response = await api1.post(
        SAVE_VALUE_MAPPING,
        data,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const syncMapping = async () => {
    const data = {
      sourceSystem: sourcesystem,
    };

    try {
      // add api call
      setLoading(true);
      const response = await api1.post(
       SYNC_TRIGGER,
        data,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const resetEditing = () => {
    setEditVisible(false);
    setEditingMapping(null);
  };

  const resetAdd = () => {
    setAddVisible(false);
    setAddMapping(null);
  };

  return (
    <Container style={{ marginTop: 50, marginBottom: 50}}>
      <span style={{fontSize: "30px", fontWeight:"bold" }}>Value Mapping</span>
      <Row style={{marginTop: 30}}>
        <Col md={2}>
          {" "}
          <p style={{ marginTop: 5 }}><b>Source System:</b></p>{" "}
        </Col>
        <Col md={2}>
          <DropdownButton
            variant="success"
            className="dropdown-basic"
            id="dropdown-basic"
            title={sourcesystem ? sourcesystem : "Select a source system"}
            onSelect={() => {
              setSourceSystemSelected(true);
            }}
          >
            <Dropdown.Item
              className="dropdown-item"
              href=""
              onClick={(e) => {
                changeDropdownText(e.target.textContent);
                updateSourceSystem(e.target.textContent);
              }}
            >
              Apaleo
            </Dropdown.Item>
            <Dropdown.Item
              href=""
              onClick={(e) => {
                changeDropdownText(e.target.textContent);
                updateSourceSystem(e.target.textContent);
              }}
            >
              {" "}
              PMS2{" "}
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>

      {loading && (
        <div>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      {sourcesystemselected && !loading && (
        <div>
          <Row style={{ marginTop: 50 }}>
            <Col>
              <Table columns={columns} dataSource={dataSource} />
            </Col>
          </Row>
          <Row >
            <Col md={2}>
              <Button onClick={()=>setAddVisible(true)}>Add New Entry</Button>
            </Col>
            <Col md={2}>
              <Button onClick={()=>syncMapping()} >Sync</Button>
            </Col>
          </Row>
        </div>
      )}
      <Modal
        title="Edit Mapping"
        open={editvisible}
        okText="Save"
        onCancel={() => resetEditing()}
        onOk={() => {
          setDataSource((pre) => {
            return pre.map((mapping) => {
              if (mapping.id === editingmapping.id) {
                console.log(editingmapping);
                updateMapping(editingmapping);
                return editingmapping;
              } else {
                return mapping;
              }
            });
          });
          resetEditing();
        }}
      >
        <div style={{ marginTop: 10 }}>
          <span>Mapping From:</span>
          <Input
            value={editingmapping?.key}
            onChange={(e) => {
              setEditingMapping((pre) => {
                return { ...pre, key: e.target.value };
              });
            }}
            disabled="true"
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <span>Mapping To:</span>
          <Input
            value={editingmapping?.value}
            onChange={(e) => {
              setEditingMapping((pre) => {
                return { ...pre, value: e.target.value };
              });
            }}
          />
        </div>
      </Modal>

      <Modal
        title="Add Mapping"
        open={addvisible}
        okText="Save"
        onCancel={() => resetAdd()}
        onOk={() => {
          onAddMapping();
          resetAdd();
        }}
      >
        <div style={{ marginTop: 10 }}>
          <span>Mapping From:</span>
          <Input
            
            onChange={(e) => setNewFromText(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <span>Mapping To:</span>
          <Input
            
            onChange={(e) => setNewToText(e.target.value)}
          />
        </div>
      </Modal>
      
      <Modal
        title="Confirm"
        open={deletepopup}
        okText="Yes"
        onCancel={() => {setDeletePopup(false); setDeleteMapping(null)}}
        onOk={() => {
          setDataSource((pre) => {
            return pre.map((mapping) => {
              if (mapping.id === deletemapping.id) {
                console.log(deletemapping);
                onDeleteMapping(deletemapping);
                return deletemapping;
              } else {
                return mapping;
              }
            });
          });
          setDeleteMapping(null)
          setDeletePopup(false)
        }}
      >
        <div style={{ marginTop: 10 }}>
          <span><b>Are you sure you want to delete?</b></span>     
        </div>
        
      </Modal>
    </Container>
  );
}

export default ValueMapping;
