import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row, Col } from "react-grid-system";
import api1 from "../..";
import moment from "moment";
import "./dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {config,msalConfig,loginApiRequest} from "../../config";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState({
    checkins: { datasets: [] },
    bookings: { datasets: [] },
  });

  const [graphOptions, setGraphOptions] = useState({
    checkins: {
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: `Number of CheckIns`,
          },
        },
      },
    },
    bookings: {
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: `Number of CheckIns`,
          },
        },
      },
    },
  });

  let graphInfo = {
    checkins: {
      data: { datasets: [] },
      options: {},
      url: "https://api.dev.ciepocdev.com/analytics/getnumberspecificeventsinweek",
      eventtype: "CIE.POC.CheckIn-Service.Checked-In.Reservation",
      colour: "rgba(255, 99, 132, 1)",
    },
    bookings: {
      data: { datasets: [] },
      options: {},
      url: "https://api.dev.ciepocdev.com/analytics/getnumberspecificeventsinweek",
      eventtype: "CIE.POC.Booking-Service.Success.Booking",
      colour: "rgba(255, 200, 135, 1)",
    },
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    // load data for all the graphs
    console.log("hey");
    const updateData = async () => {
      // loop through each graph type and collect their data and store the info
      let tempGraphOptions = {};
      let tempGraphData = {
        checkins: { data: { datasets: [] } },
        bookings: { data: { datasets: [] } },
      };

      setLoading(true);

      for (let k in graphInfo) {
        // make call to the api
        try {
          const data = {
            eventType: graphInfo[k].eventtype,
          };

          const response = await api1.post(graphInfo[k].url, data, {
            headers: {
              "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
              "Content-Type": "application/json"
              
            },
          });

          response.data.result.reverse();
          let newlabels = [...new Array(7)].map((i, idx) =>
            moment().startOf("day").subtract(idx, "days").format("MM/DD/yyyy")
          );

          const datatoadd = {
            labels: newlabels.reverse(),
            datasets: [
              {
                label: `Number of ${k} in the last 7 days`,
                data: response.data.result,
                backgroundColor: graphInfo[k].colour,
              },
            ],
          };
          graphInfo[k].data = datatoadd;

          console.log(response.data.result);
        } catch (error) {
          console.log(error);
        }

        const options = {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: `Number of ${k}`,
            },
          },
        };

        tempGraphOptions[k] = options;
        tempGraphData[k] = graphInfo[k].data;
      }
      console.log(tempGraphData);
      console.log(tempGraphOptions);
      setGraphOptions(tempGraphOptions);
      setGraphData(tempGraphData);
      setLoading(false);
    };

    updateData();
  }, []);

  return (
    <Container
      style={{
        marginTop: 50,
        marginBottom: 50,
      }}
    >
      <span
        style={{ marginLeft: "20px", fontSize: "30px", fontWeight: "bold" }}
      >
        Dashboard
      </span>
      {loading && (
        <div>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!loading && (
        <>
          <Row
            justify="between"
            position="fixed"
            styles={{
              justifyContent: "space-between",
              width: "100%",
              position: "fixed",
            }}
          >
            <Col md={1} lg={2} xl={4} xxl={4}>
              <div className="analytics-box-dashboard border border-secondary">
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Check-In Analytics
                </span>
                <Bar
                  options={graphOptions.checkins.options}
                  data={graphData.checkins}
                />
              </div>
            </Col>
            <Col md={3.5} lg={5.5} xl={6.5} xxl={8}>
              <div className="analytics-box-dashboard border border-secondary">
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Booking Analytics
                </span>
                <Bar
                  options={graphOptions.bookings.options}
                  data={graphData.bookings}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Dashboard;
